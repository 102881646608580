// Maps to checkout page type in directus
export const CheckoutPageType = {
  SAMCART: 'samcart',
  SHOPIFY: 'shopify',
  SHOPIFY_ONE_PAGE: 'shopifyOnePage',
};

// More general checkout type used in tracking events
export const CheckoutType = {
  ONE_PAGE: 'OnePage',
  TWO_PAGE: 'TwoPage',
};

export const CheckoutStep = {
  INFORMATION: 'information',
  PAYMENT: 'payment',
};

export const CheckoutStepOrder = [CheckoutStep.INFORMATION, CheckoutStep.PAYMENT];

export const CheckoutStepLabel = {
  [CheckoutStep.INFORMATION]: 'Information',
  [CheckoutStep.PAYMENT]: 'Payment',
};

export const CheckoutFinishUrlToken = 'finish';

// https://github.com/ajv-validator/ajv-formats/blob/4dd65447575b35d0187c6b125383366969e6267e/src/formats.ts#L65
export const CheckoutEmailRegExp =
  /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;

export const CheckoutLocation = {
  SALES_PAGE: 'SalesPage',
  CHECKOUT_PAGE: 'CheckoutPage',
};
