export const OrderPaymentProcessor = {
  STRIPE: 'Stripe',
  PAYPAL: 'PayPal',
};

export const OrderPaymentMethod = {
  CREDIT_CARD: 'CreditCard',
  APPLE_PAY: 'ApplePay',
  GOOGLE_PAY: 'GooglePay',
  BROWSER_CARD: 'BrowserCard',
  PAYPAL: 'PayPal',
  AFTERPAY: 'Afterpay',
  PAYPAL_EXPRESS: 'PayPalExpress',
  CREDIT_CARD_EXPRESS: 'CreditCardExpress',
  APPLE_PAY_EXPRESS: 'ApplePayExpress',
  GOOGLE_PAY_EXPRESS: 'GooglePayExpress',
};

export const OrderStatus = {
  DRAFT: 'Draft',
  PROCESSING: 'Processing',
  COMPLETED: 'Completed',
  CANCELED: 'Canceled',
  FAILED: 'Failed',
  REFUNDED: 'Refunded',
  PARTIALLY_REFUNDED: 'PartiallyRefunded',
};
