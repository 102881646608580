export const FunnelPageType = {
  HOME_PAGE: 'HomePage',
  SEARCH_PAGE: 'SearchPage',
  ARTICLE_PAGE: 'ArticlePage',
  CATEGORY_PAGE: 'CategoryPage',
  SALES_PAGE: 'SalesPage',
  CHECKOUT_PAGE: 'CheckoutPage',
  UPSELL_PAGE: 'UpsellPage',
  PRESELL_PAGE: 'PresellPage',
  REFUND_POLICY_PAGE: 'RefundPolicyPage',
  TERMS_AND_CONDITIONS_PAGE: 'TermsAndConditionsPage',
  PRIVACY_POLICY_PAGE: 'PrivacyPolicyPage',
  CONTACT_US_PAGE: 'ContactUsPage',
  POLICY_PAGE: 'PolicyPage',
  OUR_STORY_PAGE: 'OurStoryPage',
};
