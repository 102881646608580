export const DirectusPageType = {
  HOME_PAGE: 'HomePage',
  ARTICLE_PAGE: 'ArticlePage',
  SALES_PAGE: 'SalesPage',
  CHECKOUT_PAGE: 'CheckoutPage',
  REFUND_POLICY_PAGE: 'RefundPolicyPage',
  POLICY_PAGE: 'PolicyPage',
  PRESELL_PAGE: 'PresellPage',
  OUR_STORY_PAGE: 'OurStoryPage',
};
