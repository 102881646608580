export const BuyFromType = {
  VELOCITY: 'velocity',
  AMAZON: 'amazon',
};

export const BuyFromAvailability = {
  AVAILABLE: 'available',
  SOLD_OUT: 'soldOut',
  NOT_AVAILABLE: 'notAvailable',
};
