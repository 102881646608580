export const TransactionErrorCode = {
  CARD_DECLINE_RATE_LIMIT: 'card_decline_rate_limit_exceeded',
  CARD_DECLINED: 'card_declined',
  EMAIL_INVALID: 'email_invalid',
  EXPIRED_CARD: 'expired_card',
  INCORRECT_ADDRESS: 'incorrect_address',
  INCORRECT_CVC: 'incorrect_cvc',
  INCORRECT_NUMBER: 'incorrect_number',
  INCORRECT_ZIP: 'incorrect_zip',
  INSUFFICIENT_FUNDS: 'insufficient_funds',
  INVALID_CVC: 'invalid_cvc',
  INVALID_EXPIRY_MONTH: 'invalid_expiry_month',
  INVALID_EXPIRY_YEAR: 'invalid_expiry_year',
  INVALID_NUMBER: 'invalid_number',
  POSTAL_CODE_INVALID: 'postal_code_invalid',
  OTHER: 'other',
};

export const TransactionErrorMessage = {
  [TransactionErrorCode.CARD_DECLINE_RATE_LIMIT]:
    'This card has been declined too many times. Please try again later or use a different payment method.',
  [TransactionErrorCode.CARD_DECLINED]:
    'This card has been declined. Please try again or use a different payment method.',
  [TransactionErrorCode.EMAIL_INVALID]:
    'Something is wrong with your email address. Check your email and try again.',
  [TransactionErrorCode.EXPIRED_CARD]:
    'This card has expired. Check the expiration date or use a different payment method.',
  [TransactionErrorCode.INCORRECT_ADDRESS]:
    "The billing address used doesn't match the card's address. Check the card's address or use a different payment method",
  [TransactionErrorCode.INCORRECT_CVC]:
    "The card's security code is incorrect. Check the card's security code or use a different payment method.",
  [TransactionErrorCode.INCORRECT_NUMBER]:
    'The card number is incorrect. Check the card number or use a different payment method.',
  [TransactionErrorCode.INCORRECT_ZIP]:
    "The card's zip code is incorrect. Check the card's zip code or use a different payment method.",
  [TransactionErrorCode.INSUFFICIENT_FUNDS]:
    'Your account has insufficient funds to cover this payment. Try another payment method.',
  [TransactionErrorCode.INVALID_CVC]:
    "The card's security code is invalid. Check the card's security code or use a different payment method.",
  [TransactionErrorCode.INVALID_EXPIRY_MONTH]:
    "The card's expiration month is invalid. Check the card's expiration month or use a different payment method.",
  [TransactionErrorCode.INVALID_EXPIRY_YEAR]:
    "The card's expiration year is invalid. Check the card's expiration year or use a different payment method.",
  [TransactionErrorCode.INVALID_NUMBER]:
    'The card number is invalid. Check the card number or use a different payment method.',
  [TransactionErrorCode.POSTAL_CODE_INVALID]:
    "The card's postal code is incorrect. Check the card's postal code or use a different payment method.",
  [TransactionErrorCode.OTHER]:
    'There was an error processing your payment. Please try again or use a different payment method.',
};
