export const PayPalButtonStyleColor = {
  GOLD: 'gold',
  BLUE: 'blue',
  SILVER: 'silver',
  WHITE: 'white',
  BLACK: 'black',
};

export const PayPalButtonStyleLayout = { VERTICAL: 'vertical', HORIZONTAL: 'horizontal' };

export const PayPalButtonStyleShape = { RECTANGLE: 'rect', PILL: 'pill' };

export const DEFAULT_PAYPAL_BUTTON_STYLE = {
  layout: PayPalButtonStyleLayout.VERTICAL,
  color: PayPalButtonStyleColor.GOLD,
  shape: PayPalButtonStyleShape.RECTANGLE,
  height: 55,
};
