export const CountryStates = {
  AF: {
    label: 'Afghanistan',
  },
  AX: {
    label: 'Aland Islands',
  },
  AL: {
    label: 'Albania',
  },
  DZ: {
    label: 'Algeria',
  },
  AD: {
    label: 'Andorra',
  },
  AO: {
    label: 'Angola',
  },
  AI: {
    label: 'Anguilla',
  },
  AG: {
    label: 'Antigua And Barbuda',
  },
  AR: {
    label: 'Argentina',
    states: {
      B: 'Buenos Aires',
      K: 'Catamarca',
      H: 'Chaco',
      U: 'Chubut',
      C: 'Ciudad Autónoma de Buenos Aires',
      X: 'Córdoba',
      W: 'Corrientes',
      E: 'Entre Ríos',
      P: 'Formosa',
      Y: 'Jujuy',
      L: 'La Pampa',
      F: 'La Rioja',
      M: 'Mendoza',
      N: 'Misiones',
      Q: 'Neuquén',
      R: 'Río Negro',
      A: 'Salta',
      J: 'San Juan',
      D: 'San Luis',
      Z: 'Santa Cruz',
      S: 'Santa Fe',
      G: 'Santiago Del Estero',
      V: 'Tierra Del Fuego',
      T: 'Tucumán',
    },
  },
  AM: {
    label: 'Armenia',
  },
  AW: {
    label: 'Aruba',
  },
  AC: {
    label: 'Ascension Island',
  },
  AU: {
    label: 'Australia',
    states: {
      ACT: 'Australian Capital Territory',
      NSW: 'New South Wales',
      NT: 'Northern Territory',
      QLD: 'Queensland',
      SA: 'South Australia',
      TAS: 'Tasmania',
      VIC: 'Victoria',
      WA: 'Western Australia',
    },
  },
  AT: {
    label: 'Austria',
  },
  AZ: {
    label: 'Azerbaijan',
  },
  BS: {
    label: 'Bahamas',
  },
  BH: {
    label: 'Bahrain',
  },
  BD: {
    label: 'Bangladesh',
  },
  BB: {
    label: 'Barbados',
  },
  BY: {
    label: 'Belarus',
  },
  BE: {
    label: 'Belgium',
  },
  BZ: {
    label: 'Belize',
  },
  BJ: {
    label: 'Benin',
  },
  BM: {
    label: 'Bermuda',
  },
  BT: {
    label: 'Bhutan',
  },
  BO: {
    label: 'Bolivia',
  },
  BA: {
    label: 'Bosnia And Herzegovina',
  },
  BW: {
    label: 'Botswana',
  },
  BV: {
    label: 'Bouvet Island',
  },
  BR: {
    label: 'Brazil',
    states: {
      AC: 'Acre',
      AL: 'Alagoas',
      AP: 'Amapá',
      AM: 'Amazonas',
      BA: 'Bahia',
      CE: 'Ceará',
      DF: 'Distrito Federal',
      ES: 'Espírito Santo',
      GO: 'Goiás',
      MA: 'Maranhão',
      MT: 'Mato Grosso',
      MS: 'Mato Grosso do Sul',
      MG: 'Minas Gerais',
      PA: 'Pará',
      PB: 'Paraíba',
      PR: 'Paraná',
      PE: 'Pernambuco',
      PI: 'Piauí',
      RJ: 'Rio de Janeiro',
      RN: 'Rio Grande do Norte',
      RS: 'Rio Grande do Sul',
      RO: 'Rondônia',
      RR: 'Roraima',
      SC: 'Santa Catarina',
      SP: 'São Paulo',
      SE: 'Sergipe',
      TO: 'Tocantins',
    },
  },
  IO: {
    label: 'British Indian Ocean Territory',
  },
  BN: {
    label: 'Brunei',
  },
  BG: {
    label: 'Bulgaria',
  },
  BF: {
    label: 'Burkina Faso',
  },
  BI: {
    label: 'Burundi',
  },
  KH: {
    label: 'Cambodia',
  },
  CA: {
    label: 'Canada',
    states: {
      AB: 'Alberta',
      BC: 'British Columbia',
      MB: 'Manitoba',
      NB: 'New Brunswick',
      NL: 'Newfoundland and Labrador',
      NT: 'Northwest Territories',
      NS: 'Nova Scotia',
      NU: 'Nunavut',
      ON: 'Ontario',
      PE: 'Prince Edward Island',
      QC: 'Quebec',
      SK: 'Saskatchewan',
      YT: 'Yukon',
    },
  },
  CV: {
    label: 'Cape Verde',
  },
  BQ: {
    label: 'Caribbean Netherlands',
  },
  KY: {
    label: 'Cayman Islands',
  },
  CF: {
    label: 'Central African Republic',
  },
  TD: {
    label: 'Chad',
  },
  CL: {
    label: 'Chile',
    states: {
      AP: 'Arica and Parinacota',
      TA: 'Tarapacá',
      AN: 'Antofagasta',
      AT: 'Atacama',
      CO: 'Coquimbo',
      VS: 'Valparaíso',
      RM: 'Santiago',
      LI: 'O’Higgins',
      ML: 'Maule',
      NB: 'Ñuble',
      BI: 'Biobío',
      AR: 'Araucanía',
      LR: 'Los Ríos',
      LL: 'Los Lagos',
      AI: 'Aysén',
      MA: 'Magallanes',
    },
  },
  CN: {
    label: 'China',
    states: {
      AH: 'Anhui',
      BJ: 'Beijing',
      CQ: 'Chongqing',
      FJ: 'Fujian',
      GS: 'Gansu',
      GD: 'Guangdong',
      GX: 'Guangxi',
      GZ: 'Guizhou',
      HI: 'Hainan',
      HE: 'Hebei',
      HL: 'Heilongjiang',
      HA: 'Henan',
      HB: 'Hubei',
      HN: 'Hunan',
      NM: 'Inner Mongolia',
      JS: 'Jiangsu',
      JX: 'Jiangxi',
      JL: 'Jilin',
      LN: 'Liaoning',
      NX: 'Ningxia',
      QH: 'Qinghai',
      SN: 'Shaanxi',
      SD: 'Shandong',
      SH: 'Shanghai',
      SX: 'Shanxi',
      SC: 'Sichuan',
      TJ: 'Tianjin',
      XJ: 'Xinjiang',
      YZ: 'Xizang',
      YN: 'Yunnan',
      ZJ: 'Zhejiang',
    },
  },
  CX: {
    label: 'Christmas Island',
  },
  CC: {
    label: 'Cocos (Keeling) Islands',
  },
  CO: {
    label: 'Colombia',
    states: {
      AMA: 'Amazonas',
      ANT: 'Antioquia',
      ARA: 'Arauca',
      ATL: 'Atlántico',
      DC: 'Bogotá, D.C.',
      BOL: 'Bolívar',
      BOY: 'Boyacá',
      CAL: 'Caldas',
      CAQ: 'Caquetá',
      CAS: 'Casanare',
      CAU: 'Cauca',
      CES: 'Cesar',
      CHO: 'Chocó',
      COR: 'Córdoba',
      CUN: 'Cundinamarca',
      GUA: 'Guainía',
      GUV: 'Guaviare',
      HUI: 'Huila',
      LAG: 'La Guajira',
      MAG: 'Magdalena',
      MET: 'Meta',
      NAR: 'Nariño',
      NSA: 'Norte de Santander',
      PUT: 'Putumayo',
      QUI: 'Quindío',
      RIS: 'Risaralda',
      SAP: 'San Andrés, Providencia y Santa Catalina',
      SAN: 'Santander',
      SUC: 'Sucre',
      TOL: 'Tolima',
      VAC: 'Valle del Cauca',
      VAU: 'Vaupés',
      VID: 'Vichada',
    },
  },
  KM: {
    label: 'Comoros',
  },
  CG: {
    label: 'Congo',
  },
  CD: {
    label: 'Congo, The Democratic Republic Of The',
  },
  CK: {
    label: 'Cook Islands',
  },
  CR: {
    label: 'Costa Rica',
  },
  HR: {
    label: 'Croatia',
  },
  CU: {
    label: 'Cuba',
  },
  CW: {
    label: 'Curaçao',
  },
  CY: {
    label: 'Cyprus',
  },
  CZ: {
    label: 'Czech Republic',
  },
  CI: {
    label: 'Côte d’Ivoire',
  },
  DK: {
    label: 'Denmark',
  },
  DJ: {
    label: 'Djibouti',
  },
  DM: {
    label: 'Dominica',
  },
  DO: {
    label: 'Dominican Republic',
  },
  EC: {
    label: 'Ecuador',
  },
  EG: {
    label: 'Egypt',
    states: {
      SU: '6th of October',
      SHR: 'Al Sharqia',
      ALX: 'Alexandria',
      ASN: 'Aswan',
      AST: 'Asyut',
      BH: 'Beheira',
      BNS: 'Beni Suef',
      C: 'Cairo',
      DK: 'Dakahlia',
      DT: 'Damietta',
      FYM: 'Faiyum',
      GH: 'Gharbia',
      GZ: 'Giza',
      HU: 'Helwan',
      IS: 'Ismailia',
      KFS: 'Kafr el-Sheikh',
      LX: 'Luxor',
      MT: 'Matrouh',
      MN: 'Minya',
      MNF: 'Monufia',
      WAD: 'New Valley',
      SIN: 'North Sinai',
      PTS: 'Port Said',
      KB: 'Qalyubia',
      KN: 'Qena',
      BA: 'Red Sea',
      SHG: 'Sohag',
      JS: 'South Sinai',
      SUZ: 'Suez',
    },
  },
  SV: {
    label: 'El Salvador',
  },
  GQ: {
    label: 'Equatorial Guinea',
  },
  ER: {
    label: 'Eritrea',
  },
  EE: {
    label: 'Estonia',
  },
  SZ: {
    label: 'Eswatini',
  },
  ET: {
    label: 'Ethiopia',
  },
  FK: {
    label: 'Falkland Islands (Malvinas)',
  },
  FO: {
    label: 'Faroe Islands',
  },
  FJ: {
    label: 'Fiji',
  },
  FI: {
    label: 'Finland',
  },
  FR: {
    label: 'France',
  },
  GF: {
    label: 'French Guiana',
  },
  PF: {
    label: 'French Polynesia',
  },
  TF: {
    label: 'French Southern Territories',
  },
  GA: {
    label: 'Gabon',
  },
  GM: {
    label: 'Gambia',
  },
  GE: {
    label: 'Georgia',
  },
  DE: {
    label: 'Germany',
  },
  GH: {
    label: 'Ghana',
  },
  GI: {
    label: 'Gibraltar',
  },
  GR: {
    label: 'Greece',
  },
  GL: {
    label: 'Greenland',
  },
  GD: {
    label: 'Grenada',
  },
  GP: {
    label: 'Guadeloupe',
  },
  GT: {
    label: 'Guatemala',
    states: {
      AVE: 'Alta Verapaz',
      BVE: 'Baja Verapaz',
      CMT: 'Chimaltenango',
      CQM: 'Chiquimula',
      EPR: 'El Progreso',
      ESC: 'Escuintla',
      GUA: 'Guatemala',
      HUE: 'Huehuetenango',
      IZA: 'Izabal',
      JAL: 'Jalapa',
      JUT: 'Jutiapa',
      PET: 'Petén',
      QUE: 'Quetzaltenango',
      QUI: 'Quiché',
      RET: 'Retalhuleu',
      SAC: 'Sacatepéquez',
      SMA: 'San Marcos',
      SRO: 'Santa Rosa',
      SOL: 'Sololá',
      SUC: 'Suchitepéquez',
      TOT: 'Totonicapán',
      ZAC: 'Zacapa',
    },
  },
  GG: {
    label: 'Guernsey',
  },
  GN: {
    label: 'Guinea',
  },
  GW: {
    label: 'Guinea Bissau',
  },
  GY: {
    label: 'Guyana',
  },
  HT: {
    label: 'Haiti',
  },
  HM: {
    label: 'Heard Island And Mcdonald Islands',
  },
  VA: {
    label: 'Holy See (Vatican City State)',
  },
  HN: {
    label: 'Honduras',
  },
  HK: {
    label: 'Hong Kong',
    states: {
      HK: 'Hong Kong Island',
      KL: 'Kowloon',
      NT: 'New Territories',
    },
  },
  HU: {
    label: 'Hungary',
  },
  IS: {
    label: 'Iceland',
  },
  IN: {
    label: 'India',
    states: {
      AN: 'Andaman and Nicobar Islands',
      AP: 'Andhra Pradesh',
      AR: 'Arunachal Pradesh',
      AS: 'Assam',
      BR: 'Bihar',
      CH: 'Chandigarh',
      CG: 'Chhattisgarh',
      DN: 'Dadra and Nagar Haveli',
      DD: 'Daman and Diu',
      DL: 'Delhi',
      GA: 'Goa',
      GJ: 'Gujarat',
      HR: 'Haryana',
      HP: 'Himachal Pradesh',
      JK: 'Jammu and Kashmir',
      JH: 'Jharkhand',
      KA: 'Karnataka',
      KL: 'Kerala',
      LA: 'Ladakh',
      LD: 'Lakshadweep',
      MP: 'Madhya Pradesh',
      MH: 'Maharashtra',
      MN: 'Manipur',
      ML: 'Meghalaya',
      MZ: 'Mizoram',
      NL: 'Nagaland',
      OR: 'Odisha',
      PY: 'Puducherry',
      PB: 'Punjab',
      RJ: 'Rajasthan',
      SK: 'Sikkim',
      TN: 'Tamil Nadu',
      TS: 'Telangana',
      TR: 'Tripura',
      UP: 'Uttar Pradesh',
      UK: 'Uttarakhand',
      WB: 'West Bengal',
    },
  },
  ID: {
    label: 'Indonesia',
    states: {
      AC: 'Aceh',
      BA: 'Bali',
      BB: 'Bangka Belitung',
      BT: 'Banten',
      BE: 'Bengkulu',
      GO: 'Gorontalo',
      JK: 'Jakarta',
      JA: 'Jambi',
      JB: 'Jawa Barat',
      JT: 'Jawa Tengah',
      JI: 'Jawa Timur',
      KB: 'Kalimantan Barat',
      KS: 'Kalimantan Selatan',
      KT: 'Kalimantan Tengah',
      KI: 'Kalimantan Timur',
      KU: 'Kalimantan Utara',
      KR: 'Kepulauan Riau',
      LA: 'Lampung',
      MA: 'Maluku',
      MU: 'Maluku Utara',
      SU: 'North Sumatra',
      NB: 'Nusa Tenggara Barat',
      NT: 'Nusa Tenggara Timur',
      PA: 'Papua',
      PB: 'Papua Barat',
      RI: 'Riau',
      SS: 'South Sumatra',
      SR: 'Sulawesi Barat',
      SN: 'Sulawesi Selatan',
      ST: 'Sulawesi Tengah',
      SG: 'Sulawesi Tenggara',
      SA: 'Sulawesi Utara',
      SB: 'West Sumatra',
      YO: 'Yogyakarta',
    },
  },
  IR: {
    label: 'Iran, Islamic Republic Of',
  },
  IQ: {
    label: 'Iraq',
  },
  IE: {
    label: 'Ireland',
    states: {
      CW: 'Carlow',
      CN: 'Cavan',
      CE: 'Clare',
      CO: 'Cork',
      DL: 'Donegal',
      D: 'Dublin',
      G: 'Galway',
      KY: 'Kerry',
      KE: 'Kildare',
      KK: 'Kilkenny',
      LS: 'Laois',
      LM: 'Leitrim',
      LK: 'Limerick',
      LD: 'Longford',
      LH: 'Louth',
      MO: 'Mayo',
      MH: 'Meath',
      MN: 'Monaghan',
      OY: 'Offaly',
      RN: 'Roscommon',
      SO: 'Sligo',
      TA: 'Tipperary',
      WD: 'Waterford',
      WH: 'Westmeath',
      WX: 'Wexford',
      WW: 'Wicklow',
    },
  },
  IM: {
    label: 'Isle Of Man',
  },
  IL: {
    label: 'Israel',
  },
  IT: {
    label: 'Italy',
    states: {
      AG: 'Agrigento',
      AL: 'Alessandria',
      AN: 'Ancona',
      AO: 'Aosta',
      AR: 'Arezzo',
      AP: 'Ascoli Piceno',
      AT: 'Asti',
      AV: 'Avellino',
      BA: 'Bari',
      BT: 'Barletta-Andria-Trani',
      BL: 'Belluno',
      BN: 'Benevento',
      BG: 'Bergamo',
      BI: 'Biella',
      BO: 'Bologna',
      BZ: 'Bolzano',
      BS: 'Brescia',
      BR: 'Brindisi',
      CA: 'Cagliari',
      CL: 'Caltanissetta',
      CB: 'Campobasso',
      CI: 'Carbonia-Iglesias',
      CE: 'Caserta',
      CT: 'Catania',
      CZ: 'Catanzaro',
      CH: 'Chieti',
      CO: 'Como',
      CS: 'Cosenza',
      CR: 'Cremona',
      KR: 'Crotone',
      CN: 'Cuneo',
      EN: 'Enna',
      FM: 'Fermo',
      FE: 'Ferrara',
      FI: 'Firenze',
      FG: 'Foggia',
      FC: 'Forlì-Cesena',
      FR: 'Frosinone',
      GE: 'Genova',
      GO: 'Gorizia',
      GR: 'Grosseto',
      IM: 'Imperia',
      IS: 'Isernia',
      AQ: 'L’Aquila',
      SP: 'La Spezia',
      LT: 'Latina',
      LE: 'Lecce',
      LC: 'Lecco',
      LI: 'Livorno',
      LO: 'Lodi',
      LU: 'Lucca',
      MC: 'Macerata',
      MN: 'Mantova',
      MS: 'Massa-Carrara',
      MT: 'Matera',
      VS: 'Medio Campidano',
      ME: 'Messina',
      MI: 'Milano',
      MO: 'Modena',
      MB: 'Monza e Brianza',
      NA: 'Napoli',
      NO: 'Novara',
      NU: 'Nuoro',
      OG: 'Ogliastra',
      OT: 'Olbia-Tempio',
      OR: 'Oristano',
      PD: 'Padova',
      PA: 'Palermo',
      PR: 'Parma',
      PV: 'Pavia',
      PG: 'Perugia',
      PU: 'Pesaro e Urbino',
      PE: 'Pescara',
      PC: 'Piacenza',
      PI: 'Pisa',
      PT: 'Pistoia',
      PN: 'Pordenone',
      PZ: 'Potenza',
      PO: 'Prato',
      RG: 'Ragusa',
      RA: 'Ravenna',
      RC: 'Reggio Calabria',
      RE: 'Reggio Emilia',
      RI: 'Rieti',
      RN: 'Rimini',
      RM: 'Roma',
      RO: 'Rovigo',
      SA: 'Salerno',
      SS: 'Sassari',
      SV: 'Savona',
      SI: 'Siena',
      SR: 'Siracusa',
      SO: 'Sondrio',
      TA: 'Taranto',
      TE: 'Teramo',
      TR: 'Terni',
      TO: 'Torino',
      TP: 'Trapani',
      TN: 'Trento',
      TV: 'Treviso',
      TS: 'Trieste',
      UD: 'Udine',
      VA: 'Varese',
      VE: 'Venezia',
      VB: 'Verbano-Cusio-Ossola',
      VC: 'Vercelli',
      VR: 'Verona',
      VV: 'Vibo Valentia',
      VI: 'Vicenza',
      VT: 'Viterbo',
    },
  },
  JM: {
    label: 'Jamaica',
  },
  JP: {
    label: 'Japan',
    states: {
      'JP-01': 'Hokkaidō',
      'JP-02': 'Aomori',
      'JP-03': 'Iwate',
      'JP-04': 'Miyagi',
      'JP-05': 'Akita',
      'JP-06': 'Yamagata',
      'JP-07': 'Fukushima',
      'JP-08': 'Ibaraki',
      'JP-09': 'Tochigi',
      'JP-10': 'Gunma',
      'JP-11': 'Saitama',
      'JP-12': 'Chiba',
      'JP-13': 'Tōkyō',
      'JP-14': 'Kanagawa',
      'JP-15': 'Niigata',
      'JP-16': 'Toyama',
      'JP-17': 'Ishikawa',
      'JP-18': 'Fukui',
      'JP-19': 'Yamanashi',
      'JP-20': 'Nagano',
      'JP-21': 'Gifu',
      'JP-22': 'Shizuoka',
      'JP-23': 'Aichi',
      'JP-24': 'Mie',
      'JP-25': 'Shiga',
      'JP-26': 'Kyōto',
      'JP-27': 'Ōsaka',
      'JP-28': 'Hyōgo',
      'JP-29': 'Nara',
      'JP-30': 'Wakayama',
      'JP-31': 'Tottori',
      'JP-32': 'Shimane',
      'JP-33': 'Okayama',
      'JP-34': 'Hiroshima',
      'JP-35': 'Yamaguchi',
      'JP-36': 'Tokushima',
      'JP-37': 'Kagawa',
      'JP-38': 'Ehime',
      'JP-39': 'Kōchi',
      'JP-40': 'Fukuoka',
      'JP-41': 'Saga',
      'JP-42': 'Nagasaki',
      'JP-43': 'Kumamoto',
      'JP-44': 'Ōita',
      'JP-45': 'Miyazaki',
      'JP-46': 'Kagoshima',
      'JP-47': 'Okinawa',
    },
  },
  JE: {
    label: 'Jersey',
  },
  JO: {
    label: 'Jordan',
  },
  KZ: {
    label: 'Kazakhstan',
  },
  KE: {
    label: 'Kenya',
  },
  KI: {
    label: 'Kiribati',
  },
  KP: {
    label: 'Korea, Democratic People’s Republic Of',
  },
  XK: {
    label: 'Kosovo',
  },
  KW: {
    label: 'Kuwait',
  },
  KG: {
    label: 'Kyrgyzstan',
  },
  LA: {
    label: 'Lao People’s Democratic Republic',
  },
  LV: {
    label: 'Latvia',
  },
  LB: {
    label: 'Lebanon',
  },
  LS: {
    label: 'Lesotho',
  },
  LR: {
    label: 'Liberia',
  },
  LY: {
    label: 'Libyan Arab Jamahiriya',
  },
  LI: {
    label: 'Liechtenstein',
  },
  LT: {
    label: 'Lithuania',
  },
  LU: {
    label: 'Luxembourg',
  },
  MO: {
    label: 'Macao',
  },
  MG: {
    label: 'Madagascar',
  },
  MW: {
    label: 'Malawi',
  },
  MY: {
    label: 'Malaysia',
    states: {
      JHR: 'Johor',
      KDH: 'Kedah',
      KTN: 'Kelantan',
      KUL: 'Kuala Lumpur',
      LBN: 'Labuan',
      MLK: 'Melaka',
      NSN: 'Negeri Sembilan',
      PHG: 'Pahang',
      PNG: 'Penang',
      PRK: 'Perak',
      PLS: 'Perlis',
      PJY: 'Putrajaya',
      SBH: 'Sabah',
      SWK: 'Sarawak',
      SGR: 'Selangor',
      TRG: 'Terengganu',
    },
  },
  MV: {
    label: 'Maldives',
  },
  ML: {
    label: 'Mali',
  },
  MT: {
    label: 'Malta',
  },
  MQ: {
    label: 'Martinique',
  },
  MR: {
    label: 'Mauritania',
  },
  MU: {
    label: 'Mauritius',
  },
  YT: {
    label: 'Mayotte',
  },
  MX: {
    label: 'Mexico',
    states: {
      AGS: 'Aguascalientes',
      BC: 'Baja California',
      BCS: 'Baja California Sur',
      CAMP: 'Campeche',
      CHIS: 'Chiapas',
      CHIH: 'Chihuahua',
      DF: 'Ciudad de México',
      COAH: 'Coahuila',
      COL: 'Colima',
      DGO: 'Durango',
      GTO: 'Guanajuato',
      GRO: 'Guerrero',
      HGO: 'Hidalgo',
      JAL: 'Jalisco',
      MEX: 'México',
      MICH: 'Michoacán',
      MOR: 'Morelos',
      NAY: 'Nayarit',
      NL: 'Nuevo León',
      OAX: 'Oaxaca',
      PUE: 'Puebla',
      QRO: 'Querétaro',
      'Q ROO': 'Quintana Roo',
      SLP: 'San Luis Potosí',
      SIN: 'Sinaloa',
      SON: 'Sonora',
      TAB: 'Tabasco',
      TAMPS: 'Tamaulipas',
      TLAX: 'Tlaxcala',
      VER: 'Veracruz',
      YUC: 'Yucatán',
      ZAC: 'Zacatecas',
    },
  },
  MD: {
    label: 'Moldova, Republic of',
  },
  MC: {
    label: 'Monaco',
  },
  MN: {
    label: 'Mongolia',
  },
  ME: {
    label: 'Montenegro',
  },
  MS: {
    label: 'Montserrat',
  },
  MA: {
    label: 'Morocco',
  },
  MZ: {
    label: 'Mozambique',
  },
  MM: {
    label: 'Myanmar',
  },
  NA: {
    label: 'Namibia',
  },
  NR: {
    label: 'Nauru',
  },
  NP: {
    label: 'Nepal',
  },
  NL: {
    label: 'Netherlands',
  },
  AN: {
    label: 'Netherlands Antilles',
  },
  NC: {
    label: 'New Caledonia',
  },
  NZ: {
    label: 'New Zealand',
    states: {
      AUK: 'Auckland',
      BOP: 'Bay of Plenty',
      CAN: 'Canterbury',
      CIT: 'Chatham Islands',
      GIS: 'Gisborne',
      HKB: 'Hawke’s Bay',
      MWT: 'Manawatu-Wanganui',
      MBH: 'Marlborough',
      NSN: 'Nelson',
      NTL: 'Northland',
      OTA: 'Otago',
      STL: 'Southland',
      TKI: 'Taranaki',
      TAS: 'Tasman',
      WKO: 'Waikato',
      WGN: 'Wellington',
      WTC: 'West Coast',
    },
  },
  NI: {
    label: 'Nicaragua',
  },
  NE: {
    label: 'Niger',
  },
  NG: {
    label: 'Nigeria',
    states: {
      AB: 'Abia',
      FC: 'Abuja Federal Capital Territory',
      AD: 'Adamawa',
      AK: 'Akwa Ibom',
      AN: 'Anambra',
      BA: 'Bauchi',
      BY: 'Bayelsa',
      BE: 'Benue',
      BO: 'Borno',
      CR: 'Cross River',
      DE: 'Delta',
      EB: 'Ebonyi',
      ED: 'Edo',
      EK: 'Ekiti',
      EN: 'Enugu',
      GO: 'Gombe',
      IM: 'Imo',
      JI: 'Jigawa',
      KD: 'Kaduna',
      KN: 'Kano',
      KT: 'Katsina',
      KE: 'Kebbi',
      KO: 'Kogi',
      KW: 'Kwara',
      LA: 'Lagos',
      NA: 'Nasarawa',
      NI: 'Niger',
      OG: 'Ogun',
      ON: 'Ondo',
      OS: 'Osun',
      OY: 'Oyo',
      PL: 'Plateau',
      RI: 'Rivers',
      SO: 'Sokoto',
      TA: 'Taraba',
      YO: 'Yobe',
      ZA: 'Zamfara',
    },
  },
  NU: {
    label: 'Niue',
  },
  NF: {
    label: 'Norfolk Island',
  },
  MK: {
    label: 'North Macedonia',
  },
  NO: {
    label: 'Norway',
  },
  OM: {
    label: 'Oman',
  },
  PK: {
    label: 'Pakistan',
  },
  PS: {
    label: 'Palestinian Territory, Occupied',
  },
  PA: {
    label: 'Panama',
    states: {
      'PA-1': 'Bocas del Toro',
      'PA-4': 'Chiriquí',
      'PA-2': 'Coclé',
      'PA-3': 'Colón',
      'PA-5': 'Darién',
      'PA-EM': 'Emberá',
      'PA-6': 'Herrera',
      'PA-KY': 'Kuna Yala',
      'PA-7': 'Los Santos',
      'PA-NB': 'Ngöbe-Buglé',
      'PA-8': 'Panamá',
      'PA-10': 'Panamá Oeste',
      'PA-9': 'Veraguas',
    },
  },
  PG: {
    label: 'Papua New Guinea',
  },
  PY: {
    label: 'Paraguay',
  },
  PE: {
    label: 'Peru',
    states: {
      'PE-AMA': 'Amazonas',
      'PE-ANC': 'Áncash',
      'PE-APU': 'Apurímac',
      'PE-ARE': 'Arequipa',
      'PE-AYA': 'Ayacucho',
      'PE-CAJ': 'Cajamarca',
      'PE-CAL': 'Callao',
      'PE-CUS': 'Cuzco',
      'PE-HUV': 'Huancavelica',
      'PE-HUC': 'Huánuco',
      'PE-ICA': 'Ica',
      'PE-JUN': 'Junín',
      'PE-LAL': 'La Libertad',
      'PE-LAM': 'Lambayeque',
      'PE-LIM': 'Lima (departamento)',
      'PE-LMA': 'Lima (provincia)',
      'PE-LOR': 'Loreto',
      'PE-MDD': 'Madre de Dios',
      'PE-MOQ': 'Moquegua',
      'PE-PAS': 'Pasco',
      'PE-PIU': 'Piura',
      'PE-PUN': 'Puno',
      'PE-SAM': 'San Martín',
      'PE-TAC': 'Tacna',
      'PE-TUM': 'Tumbes',
      'PE-UCA': 'Ucayali',
    },
  },
  PH: {
    label: 'Philippines',
    states: {
      'PH-ABR': 'Abra',
      'PH-AGN': 'Agusan del Norte',
      'PH-AGS': 'Agusan del Sur',
      'PH-AKL': 'Aklan',
      'PH-ALB': 'Albay',
      'PH-ANT': 'Antique',
      'PH-APA': 'Apayao',
      'PH-AUR': 'Aurora',
      'PH-BAS': 'Basilan',
      'PH-BAN': 'Bataan',
      'PH-BTN': 'Batanes',
      'PH-BTG': 'Batangas',
      'PH-BEN': 'Benguet',
      'PH-BIL': 'Biliran',
      'PH-BOH': 'Bohol',
      'PH-BUK': 'Bukidnon',
      'PH-BUL': 'Bulacan',
      'PH-CAG': 'Cagayan',
      'PH-CAN': 'Camarines Norte',
      'PH-CAS': 'Camarines Sur',
      'PH-CAM': 'Camiguin',
      'PH-CAP': 'Capiz',
      'PH-CAT': 'Catanduanes',
      'PH-CAV': 'Cavite',
      'PH-CEB': 'Cebu',
      'PH-NCO': 'Cotabato',
      'PH-COM': 'Davao de Oro',
      'PH-DAV': 'Davao del Norte',
      'PH-DAS': 'Davao del Sur',
      'PH-DVO': 'Davao Occidental',
      'PH-DAO': 'Davao Oriental',
      'PH-DIN': 'Dinagat Islands',
      'PH-EAS': 'Eastern Samar',
      'PH-GUI': 'Guimaras',
      'PH-IFU': 'Ifugao',
      'PH-ILN': 'Ilocos Norte',
      'PH-ILS': 'Ilocos Sur',
      'PH-ILI': 'Iloilo',
      'PH-ISA': 'Isabela',
      'PH-KAL': 'Kalinga',
      'PH-LUN': 'La Union',
      'PH-LAG': 'Laguna',
      'PH-LAN': 'Lanao del Norte',
      'PH-LAS': 'Lanao del Sur',
      'PH-LEY': 'Leyte',
      'PH-MAG': 'Maguindanao',
      'PH-MAD': 'Marinduque',
      'PH-MAS': 'Masbate',
      'PH-00': 'Metro Manila',
      'PH-MSC': 'Misamis Occidental',
      'PH-MSR': 'Misamis Oriental',
      'PH-MOU': 'Mountain Province',
      'PH-NEC': 'Negros Occidental',
      'PH-NER': 'Negros Oriental',
      'PH-NSA': 'Northern Samar',
      'PH-NUE': 'Nueva Ecija',
      'PH-NUV': 'Nueva Vizcaya',
      'PH-MDC': 'Occidental Mindoro',
      'PH-MDR': 'Oriental Mindoro',
      'PH-PLW': 'Palawan',
      'PH-PAM': 'Pampanga',
      'PH-PAN': 'Pangasinan',
      'PH-QUE': 'Quezon',
      'PH-QUI': 'Quirino',
      'PH-RIZ': 'Rizal',
      'PH-ROM': 'Romblon',
      'PH-WSA': 'Samar',
      'PH-SAR': 'Sarangani',
      'PH-SIG': 'Siquijor',
      'PH-SOR': 'Sorsogon',
      'PH-SCO': 'South Cotabato',
      'PH-SLE': 'Southern Leyte',
      'PH-SUK': 'Sultan Kudarat',
      'PH-SLU': 'Sulu',
      'PH-SUN': 'Surigao del Norte',
      'PH-SUR': 'Surigao del Sur',
      'PH-TAR': 'Tarlac',
      'PH-TAW': 'Tawi-Tawi',
      'PH-ZMB': 'Zambales',
      'PH-ZAN': 'Zamboanga del Norte',
      'PH-ZAS': 'Zamboanga del Sur',
      'PH-ZSI': 'Zamboanga Sibugay',
    },
  },
  PN: {
    label: 'Pitcairn',
  },
  PL: {
    label: 'Poland',
  },
  PT: {
    label: 'Portugal',
    states: {
      'PT-20': 'Açores',
      'PT-01': 'Aveiro',
      'PT-02': 'Beja',
      'PT-03': 'Braga',
      'PT-04': 'Bragança',
      'PT-05': 'Castelo Branco',
      'PT-06': 'Coimbra',
      'PT-07': 'Évora',
      'PT-08': 'Faro',
      'PT-09': 'Guarda',
      'PT-10': 'Leiria',
      'PT-11': 'Lisboa',
      'PT-30': 'Madeira',
      'PT-12': 'Portalegre',
      'PT-13': 'Porto',
      'PT-14': 'Santarém',
      'PT-15': 'Setúbal',
      'PT-16': 'Viana do Castelo',
      'PT-17': 'Vila Real',
      'PT-18': 'Viseu',
    },
  },
  QA: {
    label: 'Qatar',
  },
  CM: {
    label: 'Republic of Cameroon',
  },
  RE: {
    label: 'Reunion',
  },
  RO: {
    label: 'Romania',
    states: {
      AB: 'Alba',
      AR: 'Arad',
      AG: 'Argeș',
      BC: 'Bacău',
      BH: 'Bihor',
      BN: 'Bistrița-Năsăud',
      BT: 'Botoșani',
      BV: 'Brașov',
      BR: 'Brăila',
      B: 'București',
      BZ: 'Buzău',
      CL: 'Călărași',
      CS: 'Caraș-Severin',
      CJ: 'Cluj',
      CT: 'Constanța',
      CV: 'Covasna',
      DB: 'Dâmbovița',
      DJ: 'Dolj',
      GL: 'Galați',
      GR: 'Giurgiu',
      GJ: 'Gorj',
      HR: 'Harghita',
      HD: 'Hunedoara',
      IS: 'Iași',
      IL: 'Ialomița',
      IF: 'Ilfov',
      MM: 'Maramureș',
      MH: 'Mehedinți',
      MS: 'Mureș',
      NT: 'Neamț',
      OT: 'Olt',
      PH: 'Prahova',
      SJ: 'Sălaj',
      SM: 'Satu Mare',
      SB: 'Sibiu',
      SV: 'Suceava',
      TR: 'Teleorman',
      TM: 'Timiș',
      TL: 'Tulcea',
      VL: 'Vâlcea',
      VS: 'Vaslui',
      VN: 'Vrancea',
    },
  },
  RU: {
    label: 'Russia',
    states: {
      AD: 'Republic of Adygeya',
      AL: 'Altai Republic',
      ALT: 'Altai Krai',
      AMU: 'Amur Oblast',
      ARK: 'Arkhangelsk Oblast',
      AST: 'Astrakhan Oblast',
      BA: 'Republic of Bashkortostan',
      BEL: 'Belgorod Oblast',
      BRY: 'Bryansk Oblast',
      BU: 'Republic of Buryatia',
      CE: 'Chechen Republic',
      CHE: 'Chelyabinsk Oblast',
      CHU: 'Chukotka Autonomous Okrug',
      CU: 'Chuvash Republic',
      DA: 'Republic of Dagestan',
      IN: 'Republic of Ingushetia',
      IRK: 'Irkutsk Oblast',
      IVA: 'Ivanovo Oblast',
      YEV: 'Jewish Autonomous Oblast',
      KB: 'Kabardino-Balkarian Republic',
      KGD: 'Kaliningrad Oblast',
      KL: 'Republic of Kalmykia',
      KLU: 'Kaluga Oblast',
      KAM: 'Kamchatka Krai',
      KC: 'Karachay–Cherkess Republic',
      KR: 'Republic of Karelia',
      KEM: 'Kemerovo Oblast',
      KHA: 'Khabarovsk Krai',
      KK: 'Republic of Khakassia',
      KHM: 'Khanty-Mansi Autonomous Okrug',
      KIR: 'Kirov Oblast',
      KO: 'Komi Republic',
      KOS: 'Kostroma Oblast',
      KDA: 'Krasnodar Krai',
      KYA: 'Krasnoyarsk Krai',
      KGN: 'Kurgan Oblast',
      KRS: 'Kursk Oblast',
      LEN: 'Leningrad Oblast',
      LIP: 'Lipetsk Oblast',
      MAG: 'Magadan Oblast',
      ME: 'Mari El Republic',
      MO: 'Republic of Mordovia',
      MOW: 'Moscow',
      MOS: 'Moscow Oblast',
      MUR: 'Murmansk Oblast',
      NIZ: 'Nizhny Novgorod Oblast',
      NGR: 'Novgorod Oblast',
      NVS: 'Novosibirsk Oblast',
      OMS: 'Omsk Oblast',
      ORE: 'Orenburg Oblast',
      ORL: 'Oryol Oblast',
      PNZ: 'Penza Oblast',
      PER: 'Perm Krai',
      PRI: 'Primorsky Krai',
      PSK: 'Pskov Oblast',
      ROS: 'Rostov Oblast',
      RYA: 'Ryazan Oblast',
      SPE: 'Saint Petersburg',
      SA: 'Sakha Republic (Yakutia)',
      SAK: 'Sakhalin Oblast',
      SAM: 'Samara Oblast',
      SAR: 'Saratov Oblast',
      SE: 'Republic of North Ossetia–Alania',
      SMO: 'Smolensk Oblast',
      STA: 'Stavropol Krai',
      SVE: 'Sverdlovsk Oblast',
      TAM: 'Tambov Oblast',
      TA: 'Republic of Tatarstan',
      TOM: 'Tomsk Oblast',
      TUL: 'Tula Oblast',
      TVE: 'Tver Oblast',
      TYU: 'Tyumen Oblast',
      TY: 'Tyva Republic',
      UD: 'Udmurtia',
      ULY: 'Ulyanovsk Oblast',
      VLA: 'Vladimir Oblast',
      VGG: 'Volgograd Oblast',
      VLG: 'Vologda Oblast',
      VOR: 'Voronezh Oblast',
      YAN: 'Yamalo-Nenets Autonomous Okrug',
      YAR: 'Yaroslavl Oblast',
      ZAB: 'Zabaykalsky Krai',
    },
  },
  RW: {
    label: 'Rwanda',
  },
  BL: {
    label: 'Saint Barthélemy',
  },
  SH: {
    label: 'Saint Helena',
  },
  KN: {
    label: 'Saint Kitts And Nevis',
  },
  LC: {
    label: 'Saint Lucia',
  },
  MF: {
    label: 'Saint Martin',
  },
  PM: {
    label: 'Saint Pierre And Miquelon',
  },
  WS: {
    label: 'Samoa',
  },
  SM: {
    label: 'San Marino',
  },
  ST: {
    label: 'Sao Tome And Principe',
  },
  SA: {
    label: 'Saudi Arabia',
  },
  SN: {
    label: 'Senegal',
  },
  RS: {
    label: 'Serbia',
  },
  SC: {
    label: 'Seychelles',
  },
  SL: {
    label: 'Sierra Leone',
  },
  SG: {
    label: 'Singapore',
  },
  SX: {
    label: 'Sint Maarten',
  },
  SK: {
    label: 'Slovakia',
  },
  SI: {
    label: 'Slovenia',
  },
  SB: {
    label: 'Solomon Islands',
  },
  SO: {
    label: 'Somalia',
  },
  ZA: {
    label: 'South Africa',
    states: {
      EC: 'Eastern Cape',
      FS: 'Free State',
      GT: 'Gauteng',
      NL: 'KwaZulu-Natal',
      LP: 'Limpopo',
      MP: 'Mpumalanga',
      NW: 'North West',
      NC: 'Northern Cape',
      WC: 'Western Cape',
    },
  },
  GS: {
    label: 'South Georgia And The South Sandwich Islands',
  },
  KR: {
    label: 'South Korea',
    states: {
      'KR-26': 'Busan',
      'KR-43': 'Chungbuk',
      'KR-44': 'Chungnam',
      'KR-27': 'Daegu',
      'KR-30': 'Daejeon',
      'KR-42': 'Gangwon',
      'KR-29': 'Gwangju',
      'KR-47': 'Gyeongbuk',
      'KR-41': 'Gyeonggi',
      'KR-48': 'Gyeongnam',
      'KR-28': 'Incheon',
      'KR-49': 'Jeju',
      'KR-45': 'Jeonbuk',
      'KR-46': 'Jeonnam',
      'KR-50': 'Sejong',
      'KR-11': 'Seoul',
      'KR-31': 'Ulsan',
    },
  },
  SS: {
    label: 'South Sudan',
  },
  ES: {
    label: 'Spain',
    states: {
      C: 'A Coruña',
      VI: 'Álava',
      AB: 'Albacete',
      A: 'Alicante',
      AL: 'Almería',
      O: 'Asturias',
      AV: 'Ávila',
      BA: 'Badajoz',
      PM: 'Balears',
      B: 'Barcelona',
      BU: 'Burgos',
      CC: 'Cáceres',
      CA: 'Cádiz',
      S: 'Cantabria',
      CS: 'Castellón',
      CE: 'Ceuta',
      CR: 'Ciudad Real',
      CO: 'Córdoba',
      CU: 'Cuenca',
      GI: 'Girona',
      GR: 'Granada',
      GU: 'Guadalajara',
      SS: 'Guipúzcoa',
      H: 'Huelva',
      HU: 'Huesca',
      J: 'Jaén',
      LO: 'La Rioja',
      GC: 'Las Palmas',
      LE: 'León',
      L: 'Lleida',
      LU: 'Lugo',
      M: 'Madrid',
      MA: 'Málaga',
      ML: 'Melilla',
      MU: 'Murcia',
      NA: 'Navarra',
      OR: 'Ourense',
      P: 'Palencia',
      PO: 'Pontevedra',
      SA: 'Salamanca',
      TF: 'Santa Cruz de Tenerife',
      SG: 'Segovia',
      SE: 'Sevilla',
      SO: 'Soria',
      T: 'Tarragona',
      TE: 'Teruel',
      TO: 'Toledo',
      V: 'Valencia',
      VA: 'Valladolid',
      BI: 'Vizcaya',
      ZA: 'Zamora',
      Z: 'Zaragoza',
    },
  },
  LK: {
    label: 'Sri Lanka',
  },
  VC: {
    label: 'St. Vincent',
  },
  SD: {
    label: 'Sudan',
  },
  SR: {
    label: 'Suriname',
  },
  SJ: {
    label: 'Svalbard And Jan Mayen',
  },
  SE: {
    label: 'Sweden',
  },
  CH: {
    label: 'Switzerland',
  },
  SY: {
    label: 'Syria',
  },
  TW: {
    label: 'Taiwan',
  },
  TJ: {
    label: 'Tajikistan',
  },
  TZ: {
    label: 'Tanzania, United Republic Of',
  },
  TH: {
    label: 'Thailand',
    states: {
      'TH-37': 'Amnat Charoen',
      'TH-15': 'Ang Thong',
      'TH-10': 'Bangkok',
      'TH-38': 'Bueng Kan',
      'TH-31': 'Buriram',
      'TH-24': 'Chachoengsao',
      'TH-18': 'Chai Nat',
      'TH-36': 'Chaiyaphum',
      'TH-22': 'Chanthaburi',
      'TH-50': 'Chiang Mai',
      'TH-57': 'Chiang Rai',
      'TH-20': 'Chon Buri',
      'TH-86': 'Chumphon',
      'TH-46': 'Kalasin',
      'TH-62': 'Kamphaeng Phet',
      'TH-71': 'Kanchanaburi',
      'TH-40': 'Khon Kaen',
      'TH-81': 'Krabi',
      'TH-52': 'Lampang',
      'TH-51': 'Lamphun',
      'TH-42': 'Loei',
      'TH-16': 'Lopburi',
      'TH-58': 'Mae Hong Son',
      'TH-44': 'Maha Sarakham',
      'TH-49': 'Mukdahan',
      'TH-26': 'Nakhon Nayok',
      'TH-73': 'Nakhon Pathom',
      'TH-48': 'Nakhon Phanom',
      'TH-30': 'Nakhon Ratchasima',
      'TH-60': 'Nakhon Sawan',
      'TH-80': 'Nakhon Si Thammarat',
      'TH-55': 'Nan',
      'TH-96': 'Narathiwat',
      'TH-39': 'Nong Bua Lam Phu',
      'TH-43': 'Nong Khai',
      'TH-12': 'Nonthaburi',
      'TH-13': 'Pathum Thani',
      'TH-94': 'Pattani',
      'TH-S': 'Pattaya',
      'TH-82': 'Phangnga',
      'TH-93': 'Phatthalung',
      'TH-56': 'Phayao',
      'TH-67': 'Phetchabun',
      'TH-76': 'Phetchaburi',
      'TH-66': 'Phichit',
      'TH-65': 'Phitsanulok',
      'TH-14': 'Phra Nakhon Si Ayutthaya',
      'TH-54': 'Phrae',
      'TH-83': 'Phuket',
      'TH-25': 'Prachin Buri',
      'TH-77': 'Prachuap Khiri Khan',
      'TH-85': 'Ranong',
      'TH-70': 'Ratchaburi',
      'TH-21': 'Rayong',
      'TH-45': 'Roi Et',
      'TH-27': 'Sa Kaeo',
      'TH-47': 'Sakon Nakhon',
      'TH-11': 'Samut Prakan',
      'TH-74': 'Samut Sakhon',
      'TH-75': 'Samut Songkhram',
      'TH-19': 'Saraburi',
      'TH-91': 'Satun',
      'TH-17': 'Sing Buri',
      'TH-33': 'Sisaket',
      'TH-90': 'Songkhla',
      'TH-64': 'Sukhothai',
      'TH-72': 'Suphan Buri',
      'TH-84': 'Surat Thani',
      'TH-32': 'Surin',
      'TH-63': 'Tak',
      'TH-92': 'Trang',
      'TH-23': 'Trat',
      'TH-34': 'Ubon Ratchathani',
      'TH-41': 'Udon Thani',
      'TH-61': 'Uthai Thani',
      'TH-53': 'Uttaradit',
      'TH-95': 'Yala',
      'TH-35': 'Yasothon',
    },
  },
  TL: {
    label: 'Timor Leste',
  },
  TG: {
    label: 'Togo',
  },
  TK: {
    label: 'Tokelau',
  },
  TO: {
    label: 'Tonga',
  },
  TT: {
    label: 'Trinidad and Tobago',
  },
  TA: {
    label: 'Tristan da Cunha',
  },
  TN: {
    label: 'Tunisia',
  },
  TR: {
    label: 'Turkey',
  },
  TM: {
    label: 'Turkmenistan',
  },
  TC: {
    label: 'Turks and Caicos Islands',
  },
  TV: {
    label: 'Tuvalu',
  },
  UG: {
    label: 'Uganda',
  },
  UA: {
    label: 'Ukraine',
  },
  AE: {
    label: 'United Arab Emirates',
    states: {
      AZ: 'Abu Dhabi',
      AJ: 'Ajman',
      DU: 'Dubai',
      FU: 'Fujairah',
      RK: 'Ras al-Khaimah',
      SH: 'Sharjah',
      UQ: 'Umm al-Quwain',
    },
  },
  GB: {
    label: 'United Kingdom',
    states: {
      BFP: 'British Forces',
      ENG: 'England',
      NIR: 'Northern Ireland',
      SCT: 'Scotland',
      WLS: 'Wales',
    },
  },
  US: {
    label: 'United States',
    states: {
      AL: 'Alabama',
      AK: 'Alaska',
      AS: 'American Samoa',
      AZ: 'Arizona',
      AR: 'Arkansas',
      CA: 'California',
      CO: 'Colorado',
      CT: 'Connecticut',
      DE: 'Delaware',
      DC: 'District of Columbia',
      FM: 'Federated States of Micronesia',
      FL: 'Florida',
      GA: 'Georgia',
      GU: 'Guam',
      HI: 'Hawaii',
      ID: 'Idaho',
      IL: 'Illinois',
      IN: 'Indiana',
      IA: 'Iowa',
      KS: 'Kansas',
      KY: 'Kentucky',
      LA: 'Louisiana',
      ME: 'Maine',
      MH: 'Marshall Islands',
      MD: 'Maryland',
      MA: 'Massachusetts',
      MI: 'Michigan',
      MN: 'Minnesota',
      MS: 'Mississippi',
      MO: 'Missouri',
      MT: 'Montana',
      NE: 'Nebraska',
      NV: 'Nevada',
      NH: 'New Hampshire',
      NJ: 'New Jersey',
      NM: 'New Mexico',
      NY: 'New York',
      NC: 'North Carolina',
      ND: 'North Dakota',
      MP: 'Northern Mariana Islands',
      OH: 'Ohio',
      OK: 'Oklahoma',
      OR: 'Oregon',
      PW: 'Palau',
      PA: 'Pennsylvania',
      PR: 'Puerto Rico',
      RI: 'Rhode Island',
      SC: 'South Carolina',
      SD: 'South Dakota',
      TN: 'Tennessee',
      TX: 'Texas',
      UT: 'Utah',
      VT: 'Vermont',
      VA: 'Virginia',
      WA: 'Washington',
      WV: 'West Virginia',
      WI: 'Wisconsin',
      WY: 'Wyoming',
      VI: 'Virgin Islands',
      AA: 'Armed Forces Americas',
      AE: 'Armed Forces Europe',
      AP: 'Armed Forces Pacific',
    },
  },
  UM: {
    label: 'United States Minor Outlying Islands',
  },
  UY: {
    label: 'Uruguay',
  },
  UZ: {
    label: 'Uzbekistan',
  },
  VU: {
    label: 'Vanuatu',
  },
  VE: {
    label: 'Venezuela',
  },
  VN: {
    label: 'Vietnam',
  },
  VG: {
    label: 'Virgin Islands, British',
  },
  WF: {
    label: 'Wallis And Futuna',
  },
  EH: {
    label: 'Western Sahara',
  },
  YE: {
    label: 'Yemen',
  },
  ZM: {
    label: 'Zambia',
  },
  ZW: {
    label: 'Zimbabwe',
  },
};

export const CountryCode = {
  AFGHANISTAN: 'AF',
  ALBANIA: 'AL',
  ALGERIA: 'DZ',
  ANDORRA: 'AD',
  ANGOLA: 'AO',
  ANTIGUA_AND_BARBUDA: 'AG',
  ARGENTINA: 'AR',
  ARMENIA: 'AM',
  AUSTRALIA: 'AU',
  AUSTRIA: 'AT',
  AZERBAIJAN: 'AZ',
  BAHAMAS: 'BS',
  BAHRAIN: 'BH',
  BANGLADESH: 'BD',
  BARBADOS: 'BB',
  BELARUS: 'BY',
  BELGIUM: 'BE',
  BELIZE: 'BZ',
  BENIN: 'BJ',
  BHUTAN: 'BT',
  BOLIVIA: 'BO',
  BOSNIA_AND_HERZEGOVINA: 'BA',
  BOTSWANA: 'BW',
  BRAZIL: 'BR',
  BRUNEI: 'BN',
  BULGARIA: 'BG',
  BURKINA_FASO: 'BF',
  BURUNDI: 'BI',
  CABO_VERDE: 'CV',
  CAMBODIA: 'KH',
  CAMEROON: 'CM',
  CANADA: 'CA',
  CENTRAL_AFRICAN_REPUBLIC: 'CF',
  CHAD: 'TD',
  CHILE: 'CL',
  CHINA: 'CN',
  COLOMBIA: 'CO',
  COMOROS: 'KM',
  CONGO_BRAZZAVILLE: 'CG',
  CONGO_KINSHASA: 'CD',
  COSTA_RICA: 'CR',
  CROATIA: 'HR',
  CUBA: 'CU',
  CYPRUS: 'CY',
  CZECH_REPUBLIC: 'CZ',
  DENMARK: 'DK',
  DJIBOUTI: 'DJ',
  DOMINICA: 'DM',
  DOMINICAN_REPUBLIC: 'DO',
  ECUADOR: 'EC',
  EGYPT: 'EG',
  EL_SALVADOR: 'SV',
  EQUATORIAL_GUINEA: 'GQ',
  ERITREA: 'ER',
  ESTONIA: 'EE',
  ESWATINI: 'SZ',
  ETHIOPIA: 'ET',
  FIJI: 'FJ',
  FINLAND: 'FI',
  FRANCE: 'FR',
  GABON: 'GA',
  GAMBIA: 'GM',
  GEORGIA: 'GE',
  GERMANY: 'DE',
  GHANA: 'GH',
  GREECE: 'GR',
  GRENADA: 'GD',
  GUATEMALA: 'GT',
  GUINEA: 'GN',
  GUINEA_BISSAU: 'GW',
  GUYANA: 'GY',
  HAITI: 'HT',
  HONDURAS: 'HN',
  HUNGARY: 'HU',
  ICELAND: 'IS',
  INDIA: 'IN',
  INDONESIA: 'ID',
  IRAN: 'IR',
  IRAQ: 'IQ',
  IRELAND: 'IE',
  ISRAEL: 'IL',
  ITALY: 'IT',
  JAMAICA: 'JM',
  JAPAN: 'JP',
  JORDAN: 'JO',
  KAZAKHSTAN: 'KZ',
  KENYA: 'KE',
  KIRIBATI: 'KI',
  NORTH_KOREA: 'KP',
  SOUTH_KOREA: 'KR',
  KUWAIT: 'KW',
  KYRGYZSTAN: 'KG',
  LAOS: 'LA',
  LATVIA: 'LV',
  LEBANON: 'LB',
  LESOTHO: 'LS',
  LIBERIA: 'LR',
  LIBYA: 'LY',
  LIECHTENSTEIN: 'LI',
  LITHUANIA: 'LT',
  LUXEMBOURG: 'LU',
  MADAGASCAR: 'MG',
  MALAWI: 'MW',
  MALAYSIA: 'MY',
  MALDIVES: 'MV',
  MALI: 'ML',
  MALTA: 'MT',
  MARSHALL_ISLANDS: 'MH',
  MAURITANIA: 'MR',
  MAURITIUS: 'MU',
  MEXICO: 'MX',
  MICRONESIA: 'FM',
  MOLDOVA: 'MD',
  MONACO: 'MC',
  MONGOLIA: 'MN',
  MONTENEGRO: 'ME',
  MOROCCO: 'MA',
  MOZAMBIQUE: 'MZ',
  MYANMAR: 'MM',
  NAMIBIA: 'NA',
  NAURU: 'NR',
  NEPAL: 'NP',
  NETHERLANDS: 'NL',
  NEW_ZEALAND: 'NZ',
  NICARAGUA: 'NI',
  NIGER: 'NE',
  NIGERIA: 'NG',
  NORTH_MACEDONIA: 'MK',
  NORWAY: 'NO',
  OMAN: 'OM',
  PAKISTAN: 'PK',
  PALAU: 'PW',
  PANAMA: 'PA',
  PAPUA_NEW_GUINEA: 'PG',
  PARAGUAY: 'PY',
  PERU: 'PE',
  PHILIPPINES: 'PH',
  POLAND: 'PL',
  PORTUGAL: 'PT',
  QATAR: 'QA',
  ROMANIA: 'RO',
  RUSSIA: 'RU',
  RWANDA: 'RW',
  SAINT_KITTS_AND_NEVIS: 'KN',
  SAINT_LUCIA: 'LC',
  SAINT_VINCENT_AND_GRENADINES: 'VC',
  SAMOA: 'WS',
  SAN_MARINO: 'SM',
  SAO_TOME_AND_PRINCIPE: 'ST',
  SAUDI_ARABIA: 'SA',
  SENEGAL: 'SN',
  SERBIA: 'RS',
  SEYCHELLES: 'SC',
  SIERRA_LEONE: 'SL',
  SINGAPORE: 'SG',
  SLOVAKIA: 'SK',
  SLOVENIA: 'SI',
  SOLOMON_ISLANDS: 'SB',
  SOMALIA: 'SO',
  SOUTH_AFRICA: 'ZA',
  SPAIN: 'ES',
  SRI_LANKA: 'LK',
  SUDAN: 'SD',
  SOUTH_SUDAN: 'SS',
  SURINAME: 'SR',
  SWEDEN: 'SE',
  SWITZERLAND: 'CH',
  SYRIA: 'SY',
  TAIWAN: 'TW',
  TAJIKISTAN: 'TJ',
  TANZANIA: 'TZ',
  THAILAND: 'TH',
  TIMOR_LESTE: 'TL',
  TOGO: 'TG',
  TONGA: 'TO',
  TRINIDAD_AND_TOBAGO: 'TT',
  TUNISIA: 'TN',
  TURKEY: 'TR',
  TURKMENISTAN: 'TM',
  TUVALU: 'TV',
  UGANDA: 'UG',
  UKRAINE: 'UA',
  UNITED_ARAB_EMIRATES: 'AE',
  UNITED_KINGDOM: 'GB',
  UNITED_STATES: 'US',
  URUGUAY: 'UY',
  UZBEKISTAN: 'UZ',
  VANUATU: 'VU',
  VATICAN_CITY: 'VA',
  VENEZUELA: 'VE',
  VIETNAM: 'VN',
  YEMEN: 'YE',
  ZAMBIA: 'ZM',
  ZIMBABWE: 'ZW',
};

function simplifyCountryStates(countryStates: any) {
  const simplified = {};

  for (const countryCode in countryStates) {
    if (Object.prototype.hasOwnProperty.call(countryStates, countryCode)) {
      simplified[countryCode] = countryStates[countryCode].label;
    }
  }

  return simplified;
}

export const CountryLabel = simplifyCountryStates(CountryStates);
